h2, 
p {
  text-align: center;
}

#aero {
  background-color: #33415C;

  @include media(tablet) {
    h2,
    p {
      text-align: right;
    }
  }

  .portfolioIconBox {
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 8vh;
  }
}

#beatmaker {
  background-color: #66666e;

  @include media(tablet) {
    h2,
    p {
      text-align: left;
    }
  }

  .portfolioIconBox {
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 8vh;
  }
}

#airClear {
  background-color: #257eca;

  @include media(tablet) {
  h2,
  p {
    text-align: right;
  }
}

  .portfolioIconBox {
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: 8vh;
  }
}

#thisDudeCodes {
  background-color: #000000;

  @include media(tablet) {
  h2,
  p {
    text-align: left;
  }
}

  .portfolioIconBox {
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 8vh;
  }
}

#residentActivity {
  background-color: #904ca3;

  @include media(tablet) {
  h2,
  p {
    text-align: right;
  }
}

  .portfolioIconBox {
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: 8vh;
  }
}

.projectContainer {
  width: 100%;
}

.flexContainer {
  justify-content: space-evenly;
  align-items: center;
  margin: 2% 0;
  flex-direction: column;

  @include media(tablet) {
    flex-direction: row;
  }
}

.descriptionContainer {
  width: 100%;
  
  @include media(tablet) {
    width: calc(100% / 2);
  }
}

.portImgContainer {
  margin: 2%;
  width: 100%;

  @include media(tablet) {
    width: calc(100% / 2 - 2%);
  }

  img {
    width: 100%;
  }
}

.portfolioIconBox {
  height: 8vh;
  width: 100%;
  display: grid;
  align-items: center;
  justify-items: center;

  li {
    height: 100%;
    // width: 44%;
    display: grid;
    align-items: center;
    // justify-content: center;
    margin: 3%;
  }

  img {
    display: block;
    height: 3.7vh;
    // width: 3vw;
  }

  .fa-fw {
    width: unset;
  }

  .svg-inline--fa {
    font-size: 3vh;
    // height: 3rem;
  }

  .svg-inline--fa.fa-fw {
    // width: 2rem;
  }

  .svg-inline--fa.fa-w-12,
  .svg-inline--fa.fa-w-16 {
    width: unset;
    align-self: center;
  }

  svg {
    // align-self: start;
    // height: 100%;
  }
}

h2 {
  &:visited {
    color: white;
  }

  &:focus {
    color: #00a7e1;
    transition: all 300ms;
  }

  &:hover {
    color: #00a7e1;
    transition: all 300ms;
  }

  &:active {
    color: #003459;
    transition: all 300ms;
  }
}


