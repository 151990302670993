.blogBackground {
  // width: 100vw;
  // height: 75vh;
  background-image  : url(../../assets/comet.jpg);
  background-size: cover;
  background-position: bottom left;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  
  h2 {
    margin-bottom: 5%;
  }
  
  h3 {
    margin-bottom: 5%;
  }
  
  h5 {
    margin-bottom: 2%;
  }
}


