h1,
h2,
h3,
h4,
h5 {
  color: #c0becd;
  // margin: 1% 0;
}

h1 {
  font-family: "Anonymous Pro", monospace;
  font-size: calc(2.5rem + (64 - 25) * ((100vw - 320px) / (1200 - 320)));
}

h2 {
  font-family: "Roboto Slab", serif;
  font-size: calc(2.4rem + (54 - 24) * ((100vw - 320px) / (1200 - 320)));
  text-align: center;
  margin-bottom: 4%;
}

h3 {
  font-family: "Anonymous Pro", monospace;
  font-size: calc(2.2rem + (40 - 22) * ((100vw - 320px) / (1200 - 320)));
}

h4 {
  font-family: "Syncopate", sans-serif;
  font-size: calc(2rem + (36 - 20) * ((100vw - 320px) / (1200 - 320)));
}

h5 {
  font-family: "Roboto Slab", serif;
  font-size: calc(1.4rem + (18 - 14) * ((100vw - 320px) / (1200 - 320)));
  color: white;
}

p {
  color: white;
  text-align: center;
  font-family: "Roboto Slab", serif;
  font-size: calc(1.6rem + (24 - 16) * ((100vw - 320px) / (1200 - 320)));
  line-height: 1.3;
  margin-bottom: 4%;
}

// footer social icons
.svg-inline--fa {
  font-size: calc(2rem + (30 - 20) * ((100vw - 320px) / (1200 - 320)));
}

span {
  text-decoration: #00a7e1 underline;

  // &:visited {
  //   color: white;
  // }

  // &:focus {
  //   color: #00a7e1;
  //   transition: all 300ms;
  // }

  // &:hover {
  //   color: #00a7e1;
  //   transition: all 300ms;
  // }

  // &:active {
  //   color: #003459;
  //   transition: all 300ms;
  // }

  // cursor: pointer;
}

a,
label,
input {
  font-family: "Roboto Slab", serif;
  text-decoration: none;
  color: white;
  font-size: calc(1.6rem + (20 - 16) * ((100vw - 320px) / (1200 - 320)));

  &:visited {
    color: white;
  }

  &:hover {
    color: #00a7e1;
    transition: all 300ms;
  }

  &:focus {
    color: #00a7e1;
    transition: all 300ms;
  }

  &:active {
    color: #003459;
    transition: all 300ms;
  }
}

.underline {
  position: relative;

  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
    &:after {
      width: 100%;
    }
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -3px;
    height: 3px;
    background-color: #00a7e1;
    width: 0;
    transition: width 0.4s;
  }
}

svg {
  color: white;
}
