#header {
  height: 13vh;
  // width: 100vw;
  padding: 2%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  background: transparent;

  @include media(tablet) {
    flex-direction: row;
  }

  nav ul {
    width: 100vw;
    margin: 0 4%;

    @include media(tablet) {
      height: 13vh;
    }
  }

  .scrolled {
    height: 0;
  }

  .navLogo {
    width: 20vw;
    display: flex;
    justify-content: center;
    text-align: center;

    h4 {
      color: #00a7e1;
      width: 100%;
      // padding: 10%;
      // border: 10px solid #00a7e1;
      // border-radius: 25px;

      @include media(laptop) {
        justify-content: flex-start;
        align-self: center;
        padding: 8%;
        margin: 2%;
      }
    }
  }
}

nav {
  width: 80vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  
  @include media(tablet) {
    // width: 50vw;
    justify-content: center;
    padding: 0 2%;
  }

  ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    @include media(tablet) {
      justify-content: flex-end;

    }
  }

  li {
    padding: 2%;
    list-style: none;
  }
}
