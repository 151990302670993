.homeContainer {
  // width: 100vw;
  height: 75vh;
  background-image: url(../../assets/tdc-milky-way-background.jpg);
  background-size: cover;
  background-position: bottom left;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    text-align: center;
  }
} 
